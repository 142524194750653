#blog .hero {
    padding: 6em 0;
    background: #aaa url(../../assets/images/blog-img/blog-hero.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: #ffffff;
}

#blog .hero h1 {
    font-weight: 600;
    font-size: 60px;
    line-height: 78px;
    font-family: 'Raleway', sans-serif;
}

#blog .hero p {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-shadow: 0px 2px 36px rgba(76, 73, 73, 0.04);
}

#blog .main-cards {
    padding-top: 5em;
}

#blog .blog-links {
    text-decoration: none;
}

#blog .blog-cards {
    padding-bottom: 42px;
    margin-bottom: 61px;
    border-bottom: 1px solid #a8b0b9;
}

#blog .blog-cards .badge {
    background-color: #eef4ff;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #1f72d1;
    padding: 2px 10px;

    text-transform: capitalize;
}

#blog .blog-cards h3,
#blog .blog-cards h3 a {
    font-family: 'Raleway', sans-serif;
    text-decoration: none;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #292929;
}

#blog .blog-cards p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #444549;
    margin-bottom: 23px;
}

#blog .blog-cards .details p {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #757575;
}

#blog .blog-cards .img-div {
    border-radius: 4px;
}

#blog .blog-cards img {
    border-radius: 4px;
    object-fit: contain;
    object-position: center;
}

#blog .input-group {
    margin-bottom: 39px;
}

#blog .search-icon {
    padding: 12px 15px;
    border-top-left-radius: 50rem;
    border-bottom-left-radius: 50rem;
    background: #fafafa;
    border: none;
}

#blog .search-icon .search {
    font-size: 18px;
    color: rgba(117, 117, 117, 1);
}

#blog input {
    border-top-right-radius: 50rem;
    border-bottom-right-radius: 50rem;
    background: #fafafa;
    border: none;
    font-size: 15px;
}

#blog input::placeholder {
    font-weight: 400;
    font-size: 15px;
    color: #757575;
}

#blog input:focus,
#blog input:focus:focus-visible {
    font-weight: 400;
    font-size: 15px;
    color: #757575;
    box-shadow: none;
}

#blog #right p {
    font-family: 'Raleway';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #292929;
    margin-bottom: 32px;
}

#blog #right .insights {
    margin-bottom: 20px;
}

#blog #right a {
    text-decoration: none;
}

#blog #right .insights .author {
    font-family: Inter;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #292929;
}

#blog #right .insights .details .author-img {
    width: 25px;
    border-radius: 50px;
    border: 1px solid #f1f0f0;
}

#blog #right .insights h3 {
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #292929;
}

#blog #right .insights .main-img {
    width: 90%;
    height: 75px;
    border-radius: 2px;
    object-fit: cover;
    object-position: center;
}

@media (max-width: 1399px) {
    #blog #right .insights .main-img {
        width: 100%;
    }
}

@media (max-width: 1199px) {
    #blog #right .insights .main-img {
        height: 50px;
        object-fit: contain;
    }
}

#blog .main-cards .no-blog {
    font-size: 20px;
}

@media (max-width: 991px) {
    #blog #mobile h3 {
        font-weight: 600;
        color: #292929;
        margin-bottom: 32px;
    }

    #blog .blog-cards .img-div {
        border-radius: 0;
        margin-bottom: 32px;
    }

    #blog .blog-cards img {
        width: 100%;
        border-radius: 0;
    }

    #blog #mobile .title {
        margin-bottom: 12px;
        margin-top: 24px;
    }

    #blog .blog-cards p {
        margin-bottom: 12px;
    }

    #blog .paginate a {
        text-decoration: none;
        border: none;
        background-color: #ffffff;
        font-size: 32px;
        color: #667085;
    }

    #blog .paginate p {
        font-weight: 500;
        font-size: 18px;
        color: #344054;
    }
}

@media (max-width: 575px) {
    #blog .blog-cards {
        border: none;
        margin-bottom: 48px;
    }

    #blog .paginate {
        border-top: 1px solid #eaecf0;
    }

    #blog .hero p {
        margin-top: 16px;
        margin-bottom: 32px;
    }

    #blog .main-cards {
        padding-top: 4em;
    }

    #blog .hero {
        padding-top: 8em;
        padding-bottom: 6em;
        background-position: top;
    }

    #blog .hero h1 {
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -0.02em;
    }

    #blog .hero p {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
    }

    #blog .search-icon {
        padding: 16px 20px;
        padding-right: 10px;
    }

    #blog input::placeholder {
        font-size: 16px;
        color: #667085;
    }

    #blog #mobile h3 {
        font-size: 20px;
        line-height: 30px;
    }
    #blog .blog-cards .badge {
        font-size: 14px;
    }

    #blog #mobile .title {
        line-height: 30px;
    }

    #blog .blog-cards .details p {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #717d8a;
    }

    #blog .paginate p {
        font-size: 14px;
    }

    #blog .paginate a {
        font-size: 28px;
    }
}
