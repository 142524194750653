#services {
    padding: 3em 0;
}

#services article {
    padding: 5em 0;
}

#services .details {
    width: 48%;
}

#services .img-div {
    width: 45%;
}

/* #services #fundraising img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    object-position: center 60%;
}

#services #analytics img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    object-position: center;
}

#services #strategy img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    object-position: center 35%;
} */

#services #advisory img {
    width: 100%;
    height: 530px;
    object-fit: cover;
    object-position: center;
}

@media (max-width: 1199px) {
    #services img:last-of-type {
        object-position: right;
    }
    /* #services #analytics img {
        height: 550px;
    }

    #services #fundraising img {
        height: 550px;
    } */

    #services #advisory img {
        height: 550px;
    }
}

@media (max-width: 991px) {
    #services .details,
    #services .img-div {
        width: 100%;
    }

    /* #services #analytics img,
    #services #fundraising img,
    #services #strategy img, */
    #services #advisory img {
        height: initial;
        object-position: center;
    }

    #services .img-div {
        width: 100%;
    }

    #services .img-div img {
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
}

@media (max-width: 575px) {
    #services article {
        padding-top: 0;
    }

    #services img.learn {
        object-position: top right;
    }

    #services article:last-of-type {
        padding-bottom: 1em;
    }

    #services .img-div img,
    #services #analytics img,
    #services #advisory img {
        height: 500px;
    }
}

#services .blue {
    width: 1.375rem;
    height: 0.4375rem;
    background-color: #0075ff;
}

#services h2 {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 1;
    color: #0a2533;
    margin-bottom: 41px;
}

#services p {
    color: #444549;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
}

@media (max-width: 1199px) {
    #services p {
        font-size: 1rem;
    }
}
@media (max-width: 425px) {
    #services h2 {
        font-weight: 600;
        font-size: 24px;
        color: #0a2533;
        margin-bottom: 32px;
    }

    #services p {
        font-size: 15px;
        line-height: 22px;
    }

    #services .img-div img,
    #services #analytics img,
    #services #advisory img {
        height: 400px;
    }
}

/* @media (max-width: 375px) {
    #services .img-div img,
    #services #analytics img,
    #services #advisory img {
     height: 300px; 
    }
} */
