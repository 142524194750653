#about .simplify {
    padding: 7em 0;
}

#about .simplify .details {
    width: 48%;
}

#about .simplify img {
    max-width: 50%;
}

@media (max-width: 1199px) {
    #about .simplify img {
        max-width: 45%;
    }
}

@media (max-width: 991px) {
    #about .simplify img {
        max-width: 60%;
    }

    #about .simplify .details {
        width: 100%;
    }
}

@media (max-width: 767px) {
    #about .simplify img {
        max-width: 100%;
        width: 100%;
        height: 400px;
        object-fit: cover;
        object-position: center;
        box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
    }
}

#about .simplify .details .blue {
    width: 22px;
    height: 7px;
    background-color: #0075ff;
}

#about .simplify .details h2 {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 1;
    color: #0a0a33;
    margin-bottom: 35px;
}

#about .simplify .details h2 span {
    color: #0075ff;
}

#about .simplify .details p {
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    color: #444549;
}

@media (max-width: 575px) {
    #about .simplify {
        padding-top: 4.5em;
        padding-bottom: 5em;
    }

    #about .simplify .details h2 {
        margin-bottom: 24px;
    }
}

@media (max-width: 425px) {
    #about .simplify .details h2 {
        font-weight: 600;
        font-size: 24px;
        color: #101828;
    }

    #about .simplify .details p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
    }
}
