/* Footer Top */
footer #footer-top {
    padding-top: 5em;
    padding-bottom: 3em;
}

footer #footer-top h5 {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5rem;
    color: #0a0a33;
}

footer #footer-top .left {
    width: 18%;
}

footer #footer-top .left .address {
    font-size: 14px;
    line-height: 1.3125rem;
    color: #000;
}

footer #footer-top .center {
    width: 40%;
}

footer #footer-top .center a {
    text-decoration: none;
    font-size: 14px;
    line-height: 1.3125rem;
    color: #0a0a33;
    transition: 300ms;
}

footer #footer-top .center a:hover,
footer #footer-top .center a:focus,
footer #footer-top .center a:active {
    font-weight: 500;
    color: #1f72d1;
}

footer #footer-top .center .icon {
    font-size: 20px;
}

footer #footer-top .right {
    width: 32%;
}

footer #footer-top .right p {
    color: #0a0a33;
    font-size: 0.875rem;
    line-height: 21px;
}

footer #footer-top .right input,
footer #footer-top .right button {
    font-family: Inter;
    font-size: 16px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

footer #footer-top .right input {
    border: 1px solid #d0d5dd;
    color: #667085;
    width: 63%;
    border-radius: 8px;
}

footer #footer-top .right button {
    background: #0075ff;
    border: 1px solid #0075ff;
    color: #fff;
    transition: 300ms;
    font-weight: 500;
}

footer #footer-top .right button:hover,
footer #footer-top .right button:focus {
    background: #0a0a33;
    border-color: #0a0a33;
}

@media (max-width: 1200px) {
    footer #footer-top .center,
    footer #footer-top .left {
        width: 48%;
    }

    footer #footer-top .right {
        width: 48%;
    }
}

@media (max-width: 992px) {
    footer #footer-top .center {
        width: 68%;
    }

    footer #footer-top .left {
        width: 30%;
    }

    footer #footer-top .right {
        width: 100%;
    }
}

@media (max-width: 767px) {
    footer #footer-top h5 {
        font-weight: 600;
    }
    footer #footer-top .left .address {
        font-size: 15px;
        line-height: 1.3125rem;
        color: #667085;
    }
    footer #footer-top {
        padding-top: 3em;
    }

    footer #footer-top .left {
        width: 100%;
    }

    footer #footer-top .right {
        width: 100%;
    }

    footer #footer-top .right p {
        font-size: 15px;
        color: #667085;
    }

    footer #footer-top .center {
        width: 100%;
        margin-top: 2em;
    }

    footer .center .company,
    footer .center .legal,
    footer .center .contact {
        width: 50%;
    }

    footer #footer-top .center a {
        font-size: 15px;
        line-height: 24px;
        color: #667085;
    }

    footer #footer-top .icon {
        display: none;
    }

    footer #footer-top .right input {
        width: 100%;
    }

    footer #footer-bottom .copyright {
        font-size: 15px;
        line-height: 24px;

        color: #98a2b3;
    }
}

/* Footer Bottom */
footer #footer-bottom {
    padding: 2em 0;
    border-top: 1px solid #bbbbbb;
}

footer #footer-bottom .copyright {
    color: #4d4d4d;
    font-size: 0.875rem;
}

footer #footer-bottom .social-icons a {
    color: #000;
    text-decoration: none;
    font-size: 20px;
    transition: 500ms;
}

footer #footer-bottom .social-icons a:hover,
footer #footer-bottom .social-icons a:focus,
footer #footer-bottom .social-icons a:active {
    color: #667085;
}

@media (max-width: 767px) {
    footer #footer-bottom {
        padding-bottom: 1.5em;
        padding-top: 0;
        border: none;
    }

    footer #footer-bottom .container {
        border-top: 1px solid #eaecf0;
        padding-top: 1.5em;
    }

    footer #footer-top .right button {
        border: none;
        font-weight: 600;
        font-size: 16px;
        width: 100%;
    }
}
