#about .values {
    padding-top: 6em;
}

#about .values .container {
    padding-bottom: 6em;
    border-bottom: 1px solid #e6e6e6;
}

#about .values h2 {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 1;
    color: #0a0a33;
}

#about .values h2 span {
    color: #0075ff;
}

#about .values h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #0c0c26;
}

#about .values p {
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    color: #444549;
}

@media (max-width: 575px) {
    #about .values {
        padding-top: 2.5em;
    }

    #about .values .container {
        padding-bottom: 2.5em;
    }

    #about .values h2 {
        margin-bottom: 24px;
    }
}

@media (max-width: 425px) {
    #about .values h2 {
        font-weight: 600;
        font-size: 24px;
        color: #101828;
    }

    #about .values h3 {
        font-size: 18px;
        line-height: 27px;
        color: #292929;
    }

    #about .values p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
    }
}
