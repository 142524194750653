/* Home About */
#home .home-about {
    background-color: #fcfdff;
    padding-top: 12em;
    padding-bottom: 5em;
}

/* Mission */
#home .home-about #mission {
    padding-bottom: 5em;
}
#home .home-about #mission h2 {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 3.125rem;
    line-height: 100%;
    color: #0a0a33;
}

#home .home-about #mission h2 span {
    color: #0075ff;
}

#home .home-about #mission p {
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    color: #444549;
    margin-bottom: 55px;
}

#home .home-about #mission .circle-div {
    position: relative;
    padding-top: 56px;
    /* padding-bottom: 5em; */
}

#home .home-about #mission .circle {
    border: 0.1416rem solid rgba(58, 93, 208, 0.36);
    border-radius: 100%;
    /* width: 34.375rem;
    height: 34.375rem; */
    width: 600px;
    height: 600px;
    padding: 3em;
}

#home .home-about #mission .circle-bottom {
    position: relative;
    bottom: 8rem;
}

#home .home-about #mission .circle-div h4 {
    font-family: 'Raleway';
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2.25rem;
    color: #0a0a33;
    letter-spacing: 2px;
    text-transform: uppercase;
}

#home .home-about #mission .circle-div p {
    margin-bottom: 0;
    font-weight: 300;
    font-size: 1rem;
    line-height: 27px;
    color: #444549;
}

@media (max-width: 1199px) {
    /* #home .home-about #mission {
        padding-bottom: 13em;
    } */
    #home .home-about #mission .circle {
        width: 550px;
        height: 550px;
    }

    #home .home-about #mission .circle-bottom {
        bottom: 6rem;
    }

    #home .home-about #mission .circle-div h4 {
        font-size: 1.4rem;
    }

    #home .home-about #mission .circle-div p {
        font-size: 14px;
    }

    #home .home-about #mission .circle-left {
        position: relative;
        left: 20px;
    }

    #home .home-about #mission .circle-right {
        position: relative;
        right: 20px;
    }
}

@media (max-width: 1050px) {
    #home .home-about {
        padding-bottom: 4em;
    }
}

@media (max-width: 991px) {
    #home .home-about #mission .circle {
        width: 80%;
        height: 500px;
    }
    #home .home-about #mission .circle-bottom {
        bottom: 4rem;
    }

    #home .home-about #mission .circle-bottom .circle-left {
        left: 0;
    }

    #home .home-about #mission .circle-bottom .circle-right {
        right: 0;
        bottom: 4rem;
    }

    #home .home-about #mission .circle-div {
        padding-top: 3em;
        padding-bottom: 3em;
    }

    #home .home-about {
        padding-bottom: 3em;
    }
}

@media (max-width: 767px) {
    #home .home-about #mission .circle {
        width: 100%;
        height: 500px;
    }
    #home .home-about #mission .circle-bottom,
    #home .home-about #mission .circle-bottom .circle-right {
        bottom: 2.5rem;
    }

    #home .home-about #mission .circle-div {
        padding-top: 2em;
        padding-bottom: 2em;
    }

    #home .home-about {
        padding-bottom: 2em;
    }
}
@media (max-width: 575px) {
    #home .home-about {
        padding-top: 5em;
    }
}

@media (max-width: 500px) {
    /* #home .home-about #mission .circle {
        width: 100%;
        height: 350px;
    } */

    #home .home-about #mission .circle-bottom,
    #home .home-about #mission .circle-bottom .circle-right {
        bottom: 2rem;
    }

    #home .home-about #mission .circle-div h4 {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
    }

    #home .home-about #mission .circle-div p {
        font-weight: 300;
        font-size: 11px;
        line-height: 16px;
    }

    #home .home-about #mission .circle {
        height: 400px;
    }
}

@media (max-width: 425px) {
    #home .home-about #mission h2 {
        font-weight: 600;
        font-size: 32px;
        color: #101828;
    }

    #home .home-about #mission p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
    }
}
