/* Home Hero */
#home .home-hero {
    padding-top: 8em;
    background: linear-gradient(180deg, #ffffff 56.22%, #fcfdff 100%), #ffffff;
}

#home .home-hero h1 {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    line-height: 120%;
    color: #0a0a33;
    margin-bottom: 32px;
    font-size: 4.75rem;
}

#home .home-hero h1 span {
    color: #0075ff;
    cursor: pointer;
    transition: 300ms;
}

#home .home-hero .imagery,
#home .home-hero .blocks {
    min-height: 740px;
}

/* #home .home-hero .carousel-inner {
    min-height: 740px;
} */

#home .home-hero h1 span:hover {
    color: #056608;
}

#home .home-hero .subtitle {
    font-size: 18px;
    line-height: 30px;
    padding: 0 18em;
    color: #444549;
    margin-bottom: 40px;
}

#home .home-hero button {
    font-family: 'Inter', sans-serif;
    background: #0075ff;
    width: 25%;
    border-radius: 3.125rem;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5rem;
    margin-bottom: 125px;
}

#home .home-hero button:hover,
#home .home-hero button:focus,
#home .home-hero button:active {
    background-color: transparent;
    border: 1px solid #0075ff;
    color: #0075ff;
}

/*#home #blocks-img {
     margin-top: 16px;
    margin-bottom: 16px; 
}*/

#home .blocks {
    max-width: 100%;
}

#home .imagery img {
    width: 33%;
}

#home .imagery2 {
    margin-top: 7em;
}

#home .imagery3 {
    position: relative;
    bottom: 1.2em;
}

@media (max-width: 1399px) {
    #home .home-hero h1 {
        font-size: 5rem;
    }
    #home .imagery3 {
        bottom: 1em;
    }
}

@media (max-width: 1199px) {
    #home .home-hero h1 {
        font-size: 3rem;
    }
    #home .imagery3 {
        bottom: 0.7em;
    }
}

@media (max-width: 767px) {
    #home .imagery3 {
        bottom: 0.5em;
    }

    #home .imagery2 {
        margin-top: 4em;
    }

    #home .home-hero .imagery,
    #home .home-hero .blocks {
        min-height: 500px;
    }
}

#home .home-hero .business {
    margin-top: 125px;
    font-size: 16px;
    line-height: 1.3125rem;
    color: #505055;
}

#home .home-hero .comp-logos img {
    max-width: 100%;
    filter: grayscale(100%);
    transition: 500ms;
}

#home .home-hero .comp-logos img:hover {
    filter: none;
    transform: scale(1.2);
}

@media (max-width: 1399px) {
    #home .home-hero {
        padding-top: 6em;
    }

    #home .home-hero .subtitle {
        padding: 0 13em;
    }
}

@media (max-width: 74.9375rem) {
    #home .home-hero .subtitle {
        padding: 0 8em;
    }

    #home .home-hero button {
        width: 30%;
    }
}

@media (max-width: 61.9375rem) {
    #home .home-hero h1 {
        font-size: 2.375rem;
    }

    #home .home-hero .subtitle {
        padding: 0 3em;
    }

    #home .home-hero button {
        width: 40%;
    }
}

@media (max-width: 767px) {
    #home .home-hero {
        padding-top: 7em;
    }

    #home .home-hero h1 {
        font-size: 2.25rem;
        line-height: 130%;
    }
    #home .home-hero .subtitle {
        padding: 0;
    }

    #home .home-hero button {
        width: 50%;
    }
}

@media (max-width: 575px) {
    #home .home-hero {
        padding-top: 4em;
    }

    #home .home-hero .imagery,
    #home .home-hero .blocks {
        min-height: 480px;
    }

    #home .blocks {
        width: 90%;
    }

    #home .home-hero button {
        width: 45%;
        margin-bottom: 3em;
    }
}

@media (max-width: 425px) {
    #home .home-hero .subtitle {
        font-size: 16px;
        line-height: 24px;
    }

    #home .home-hero .business {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.01em;
    }

    #home .home-hero button {
        width: 65%;
    }

    #home .home-hero .imagery,
    #home .home-hero .blocks {
        min-height: 350px;
    }
}

#home .home-hero .carousel-inner {
    padding-top: 1.5rem;
}

@keyframes slide-out-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }
}

@-webkit-keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}
@keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@-webkit-keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}
@keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}
