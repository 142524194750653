#home #home-reviews {
    background-color: #f1f3f4;
}

#home #home-reviews h2 {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 50px;
    line-height: 65px;
    color: #373f47;
}

#home #home-reviews h2 span {
    color: #0075ff;
}

#home #home-reviews .carousel {
    width: 63%;
}

#home #home-reviews .carousel p {
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    color: #0a0a33;
    margin-bottom: 1.5em;
}

#home #home-reviews .carousel h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #0c1116;
    margin-top: 1.5em;
}

#home #home-reviews .carousel h3 span {
    font-weight: 400;
    color: #4f5b67;
}
#home #home-reviews .carousel img {
    max-width: 100%;
    object-fit: cover;
}

/* Carousel */

#home #home-reviews .carousel-caption {
    position: relative;
    right: 0;
    bottom: 0;
    left: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    padding-right: 9em;

    color: #fff;
    text-align: left;
    min-height: 400px;
}

#home #home-reviews .carousel-control-next,
#home #home-reviews .carousel-control-prev {
    position: absolute;
    bottom: 81%;
    width: 10%;
}

#home #home-reviews .carousel-control-prev {
    left: 87%;
}

#home #home-reviews .carousel-control-next {
    left: 95%;
}

#home #home-reviews .carousel-control-prev-icon {
    background-image: url(../../assets/images/home-img/arrow-left.svg);
    background-size: auto;
}

#home #home-reviews .carousel-control-next-icon {
    background-image: url(../../assets/images/home-img/arrow-right.svg);
    background-size: auto;
}

#home #home-reviews .carousel-control-next-icon::after,
#home #home-reviews .carousel-control-prev-icon::after {
    content: '';
}
/* Carousel */

@media (max-width: 1399px) {
    #home #home-reviews .carousel-caption {
        padding-right: 8em;
    }
}

@media (max-width: 1199px) {
    #home #home-reviews .carousel-caption {
        padding-right: 7em;
        min-height: 500px;
    }

    #home #home-reviews .carousel {
        width: 58%;
    }

    #home #home-reviews .carousel-control-prev {
        left: 80%;
    }

    #home #home-reviews .carousel-control-next {
        left: 90%;
    }
}

@media (max-width: 991px) {
    #home #home-reviews .carousel {
        width: 100%;
    }

    #home #home-reviews .carousel-caption {
        min-height: 400px;
    }
}

@media (max-width: 767px) {
    #home #home-reviews .carousel-caption {
        padding-right: 0em;
    }

    #home #home-reviews .carousel-control-prev,
    #home #home-reviews .carousel-control-next {
        top: 0;
        bottom: -105%;
        width: 8%;
    }

    #home #home-reviews .carousel-control-prev {
        left: 0;
    }

    #home #home-reviews .carousel-control-prev-icon {
        background-image: url(../../assets/images/home-img/left.svg);
        background-size: contain;
    }

    #home #home-reviews .carousel-control-next-icon {
        background-image: url(../../assets/images/home-img/right.svg);
        background-size: contain;
    }
}

@media (max-width: 575px) {
    #home #home-reviews .carousel-caption {
        text-align: center;
        min-height: 450px;
    }

    #home #home-reviews .carousel-caption img {
        margin: auto;
    }

    #home #home-reviews .carousel p {
        margin-bottom: 32px;
    }

    #home #home-reviews .carousel h3 {
        margin-top: 1rem;
    }
}

@media (max-width: 425px) {
    #home #home-reviews .carousel p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    #home #home-reviews .carousel h3 {
        font-family: Raleway;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
    }

    #home #home-reviews .carousel h3 span {
        font-family: Inter;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #667085;
    }

    #home #home-reviews h2 {
        font-weight: 600;
        font-size: 32px;
        color: #101828;
    }
}

@media (max-width: 424px) {
    #home #home-reviews .carousel-caption {
        min-height: 500px;
    }
}

@media (max-width: 350px) {
    #home #home-reviews .carousel-caption {
        min-height: 550px;
    }
}
