* {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

html,
body {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Inter', sans-serif;
    color: #5b5757;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4;
}
