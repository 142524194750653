#about .vision {
    padding: 6em 0;
}

#about .vision h2 {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 1;
    color: #0a0a33;
}

#about .vision h2 span {
    color: #0075ff;
}

#about .vision p {
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    color: #444549;
}

@media (max-width: 575px) {
    #about .vision {
        padding: 2.5em 0;
    }

    #about .vision h2 {
        margin-bottom: 24px;
    }
}

@media (max-width: 425px) {
    #about .vision h2 {
        font-weight: 600;
        font-size: 24px;
        color: #101828;
    }

    #about .vision p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
    }
}
