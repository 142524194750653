header {
    color: #667085;
    font-weight: 400;
}

header .nav-link {
    padding: 0;
}

/* Logo */

header .header-logo {
    width: 200px;
}

.dropdown-toggle::after {
    display: none;
}

/* Navbar */
header .navbar {
    padding: 0;
}

/* All nav links */
header .navbar .nav-link,
header .navbar a {
    text-decoration: none;
    color: #667085;
    transition: 300ms;
    padding: 1.75em 0;
}

header .navbar a:hover,
header .navbar a:focus,
header .navbar a.active {
    border-top: 5px solid #0075ff;
    border-left: none;
    border-right: none;
    border-bottom: none;
    color: #0075ff;
}

header .navbar .team-link a.active {
    border: none;
    color: #667085;
}

header .navbar-expand-md .navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0;
}

header #header-nav .show > .nav-link {
    border-top: 5px solid #0075ff;
    border-left: none;
    border-right: none;
    border-bottom: none;
    color: #0075ff;
}

header #header-nav .show > .nav-link > a {
    border-top: none;
}

header .navbar .dropdown-menu {
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
        0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 8px;
    color: #0a0a33;
    transition: display 500ms;
    left: -14%;
    padding: 14px;
}
.navbar #header-nav .dropdown .dropdown-menu .dropdown-item a.active {
    border: none;
}

.navbar #header-nav .dropdown .dropdown-menu a {
    color: #0a0a33;
    padding: 0;
}

.navbar #header-nav .dropdown .dropdown-menu .dropdown-item a {
    padding: 12px;
}

.navbar
    #header-nav
    .dropdown:has(.dropdown-menu > .dropdown-item a:active)
    > #hover-dropdown {
    border-top: 5px solid #0075ff;
    border-left: none;
    border-right: none;
    border-bottom: none;
    color: #0075ff;
}

.navbar #header-nav .dropdown .dropdown-menu a:hover,
.navbar #header-nav .dropdown .dropdown-menu a:focus,
.navbar #header-nav .dropdown .dropdown-menu a:active {
    background-color: #f1f1f1;
    border: none;
    border-radius: 0;
    color: #0a0a33;
}

.navbar #header-nav .dropdown .dropdown-menu a:hover a,
.navbar #header-nav .dropdown .dropdown-menu a:focus a,
.navbar #header-nav .dropdown .dropdown-menu a:active a {
    background-color: #f1f1f1;
    border: none;
    border-radius: 0;
    color: #0a0a33;
}

header #hover-dropdown {
    margin-bottom: 0.1em;
    padding: 1.75em 0;
}

/* Navbar Brand */
header .navbar .navbar-brand:hover,
header .navbar .navbar-brand:focus,
header .navbar .navbar-brand:active {
    border: none;
    color: initial;
    padding: 0;
}

/* Offcanvas */

.offcanvas .offcanvas-body #header-nav .nav-link,
.offcanvas .offcanvas-body #header-nav a {
    text-decoration: none;
    transition: 300ms;
    padding: 1em 0;
    display: block;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #717d8a;
    text-align: left;
}

.offcanvas .offcanvas-body #header-nav a:hover,
.offcanvas .offcanvas-body #header-nav a:focus,
.offcanvas .offcanvas-body #header-nav a:active {
    color: #292929;
}

.offcanvas .offcanvas-body #header-nav > .nav-link {
    color: #292929;
}

.offcanvas .offcanvas-body #header-nav .dropdown-menu {
    border: 1px solid #eaecf0;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
        0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 8px;
    color: #0a0a33;
    transition: display 500ms;
    background-color: #fff;
    padding: 14px;

    left: 0;
}

.offcanvas .offcanvas-body #header-nav .dropdown-menu a {
    color: #0a0a33;
    padding: 0;
}

.offcanvas .offcanvas-body #header-nav .dropdown-menu .dropdown-item a {
    padding: 12px;
}

.offcanvas .offcanvas-body #header-nav .dropdown-menu a:hover,
.offcanvas .offcanvas-body #header-nav .dropdown-menu a:focus,
.offcanvas .offcanvas-body #header-nav .dropdown-menu a:active {
    background-color: #f1f1f1;
    border: none;
    border-radius: 0;
    color: #0a0a33;
}

.navbar #header-nav .dropdown .dropdown-menu a:hover a,
.navbar #header-nav .dropdown .dropdown-menu a:focus a,
.navbar #header-nav .dropdown .dropdown-menu a:active a {
    background-color: #f1f1f1;
    border: none;
    border-radius: 0;
    color: #0a0a33;
}

header .navbar-toggler {
    border: none;
}

.offcanvas .offcanvas-body #header-nav .dropdown-menu a:hover a {
    color: #0a0a33;
    background-color: transparent;
}

header .navbar-toggler-icon {
    background-image: url(../assets/images/toggler.svg);
    width: 24px;
    height: 24px;
}

.navbar-toggler:focus {
    box-shadow: none;
}

.btn-close {
    background-image: url(../assets/images/close.svg);
    background-repeat: no-repeat;
    background-size: 0.8em;
    background-position: center;
    padding: 1em;
}

.contact-btn {
    padding: 10px 0 !important;
    border-radius: 20px;
    background-color: #0075ff;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.05px;
    border: none;
}

@media (max-width: 767px) {
    /* .navbar.fixed {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 1000;
       
        left: 0;
    }

    .navbar.fixed .navbar-collapse {
        background-color: rgba(255, 255, 255, 0.95);
        border-top: 1px solid rgba(0, 0, 0, 0.2);
    } */
    /* .dropdown-toggle::after {
        display: inline-block;
        text-align: right;
        float: right;
        vertical-align: 0.5em;
    } */
    .overlay-menu {
        position: absolute;
        width: 100%; /* Set the desired width */
        left: 0;
        background-color: rgba(255, 255, 255, 0.5);
        transition: width 0.3s ease-in-out;
        z-index: 1; /* Ensure the Navbar is above the content */
    }

    /* .overlay-menu.expanded {
        width: 100%;
    } */

    .overlay-menu.expanded .navbar-collapse {
        background-color: rgba(255, 255, 255, 0.5);
        /* height: 100vh; */
        overflow-y: scroll;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    header .header-logo,
    .offcanvas .header-logo {
        width: 110px;
    }

    header .navbar .nav-link,
    header .navbar a {
        font-size: 24px;
        font-weight: 500;
        padding-top: 0;
        padding-bottom: 0.5em;
    }

    header .navbar a:hover,
    header .navbar a:focus,
    header .navbar a.active {
        border-bottom: 5px solid #0075ff;
        border-top: none;
    }

    .navbar-collapse {
        padding: 1em 0;
        /* padding-bottom: 0; */
    }

    #nav-dropdown {
        padding-bottom: 0;
    }

    #nav-dropdown:hover,
    #nav-dropdown:focus,
    #nav-dropdown:active {
        border: none;
    }

    header #header-nav .show > .nav-link {
        border: none;
    }

    header .navbar .dropdown-menu {
        margin: 18px;
    }

    .contact-btn:hover,
    .contact-btn:focus,
    .contact-btn:active {
        border: none !important;
    }
}

@media (max-width: 575px) {
    header {
        padding: 1.05rem 0;
    }
    /* Logo */
    header .navbar .nav-link,
    header .navbar a {
        font-size: 7vw;
    }
}
