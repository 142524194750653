#home .home-about #core h2 {
    font-family: 'Raleway';
    font-weight: 500;
    font-size: 3.125rem;
    line-height: 100%;
    color: #0a0a33;
}

#home .home-about #core h2 span {
    color: #0075ff;
}

#home .home-about #core p {
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    color: #444549;
}
#home .home-about #core .accordion,
#home .home-about #core .image-div {
    width: 45%;
}

#home .home-about #core .image-div img {
    transition: 15s;
}

#home .home-about #core .accordion-div {
    padding-top: 97px;
    padding-bottom: 70px;
}
#home .home-about #core .accordion {
    --bs-accordion-bg: transparent;
    --bs-accordion-border-color: rgba(0, 117, 255, 0.44);
    --bs-accordion-btn-padding-x: 0px;
    --bs-accordion-btn-color: #212529;
    --bs-accordion-btn-icon: url(../../assets/images/home-img/plus-circle.svg);
    --bs-accordion-btn-icon-transform: none;
    --bs-accordion-btn-active-icon: url(../../assets//images/home-img/minus-circle.svg);
    --bs-accordion-body-padding-x: 0;
    --bs-accordion-active-bg: transparent;
}

.accordion-button {
    font-family: 'Raleway';
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #101828;
    padding: 2.0625rem 0 3rem 0;
}

.accordion-button:not(.collapsed) {
    color: #0075ff;
    background-color: transparent;
    box-shadow: none;
    border: none;
    font-weight: 600;
    padding: 2.0625rem 0 0 0;
}

.accordion-body {
    padding-top: 1.25rem;
    padding-bottom: 3rem;
    padding-right: 1.5625rem;
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    color: #667085;
}

.accordion-flush .accordion-item:last-child {
    border-bottom: 0.0625rem solid rgba(0, 117, 255, 0.44);
}

.accordion-button:focus,
.accordion-button:focus-visible {
    border-color: transparent;
    box-shadow: none;
}

@media (max-width: 47.9375rem) {
    #home .home-about #core .extra img {
        width: 35%;
    }

    #home .home-about #core .extra h3 {
        font-family: 'Raleway';
        font-weight: 600;
        /* font-size: 18px;
line-height: 27px; */
        color: #101828;
    }

    #home .home-about #core .extra p {
        font-weight: 300;
        /* font-size: 15px;
        line-height: 24px;
        letter-spacing: -0.01em; */
        color: #444549;
    }
}

@media (max-width: 425px) {
    #home .home-about #core .extra h3 {
        font-size: 18px;
        line-height: 27px;
    }

    #home .home-about #core .extra p {
        font-size: 15px;
        line-height: 24px;
        letter-spacing: -0.01em;
    }

    #home .home-about #core h2 {
        font-weight: 600;
        font-size: 32px;
        color: #101828;
    }

    #home .home-about #core p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.02em;
    }
}
