#about .history {
    padding-top: 7em;
}

#about .history .container {
    padding-bottom: 7em;
    border-bottom: 1px solid #e6e6e6;
}

#about .history h2 {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 1;
    color: #0a0a33;
}

#about .history h2 span {
    color: #0075ff;
}

#about .history p {
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    color: #444549;
}

@media (max-width: 575px) {
    #about .history {
        padding-top: 5em;
    }

    #about .history .container {
        padding-bottom: 2.5em;
    }

    #about .history h2 {
        margin-bottom: 24px;
    }
}

@media (max-width: 425px) {
    #about .history h2 {
        font-weight: 600;
        font-size: 24px;
        color: #101828;
    }

    #about .history p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
    }
}
